import { StatusCodes } from "http-status-codes";
import {
  DifficultyLevel,
  Exam,
  GroupedQuestionInfo,
  PracticeSession,
  PracticeTemplate,
  Practice,
  QuestionPattern,
  Topic,
  Wordlist,
  PracticeTemplateSection,
  SectionQuestionInfo,
  QuestionOption,
  Question,
  WordQuestion,
  PapiPracticesPOSTResponse,
  PapiPracticeGETResponse,
  PapiError,
  PapiFilePOSTResponse,
  PapiPracticeSessionsGETResponse,
  PracticeSessionListInfoItem,
  DictionaryWord,
  WordQuestionType,
  PapiWordInfo,
  PapiPracticeSessionGETResponse,
  LearningModeFromString,
  StatusFromString,
  PapiPracticeTemplateGETResponse,
  PracticeTemplateStatusFromString,
  Status,
  PapiUserLoginPOSTRequest,
} from "../models/PracticeSession";
import { options } from "ionicons/icons";
import { PracticeTemplateData } from "../pages/PracticeTemplateListingPage";
import { fetchWrapper } from "./apiUtils";

async function loadTopicByID(id: number): Promise<Topic> {
  const response = await fetchWrapper(`/papi/institution/topic/${id}`, {
    headers: {
      Accept: "application/json",
    },
  });
  if (!response.ok) {
    return response.json().then((error) => {
      return Promise.reject(
        new Error(`failed to get topic info: ${error.code} / ${error.message}`)
      );
    });
  }

  const data: any = await response.json();

  return { name: data.name } as Topic;
}

async function loadExamByID(id: number): Promise<Exam> {
  const response = await fetchWrapper(`/papi/institution/exam/${id}`, {
    headers: {
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    return response.json().then((error) => {
      return Promise.reject(
        new Error(`failed to get exam info: ${error.code} / ${error.message}`)
      );
    });
  }

  const data: any = await response.json();

  return { name: data.name } as Exam;
}

async function loadWordlistByID(id: number): Promise<Wordlist> {
  const response = await fetchWrapper(`/papi/institution/wordlist/${id}`, {
    headers: {
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    return response.json().then((error) => {
      return Promise.reject(
        new Error(
          `failed to get wordlist info: ${error.code} / ${error.message}`
        )
      );
    });
  }

  const data: any = await response.json();

  return { name: data.name } as Wordlist;
}

async function loadPatternByID(id: number): Promise<QuestionPattern> {
  const response = await fetchWrapper(`/papi/question-pattern/${id}`, {
    headers: {
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    return response.json().then((error) => {
      return Promise.reject(
        new Error(
          `failed to get question-pattern info: ${error.code} / ${error.message}`
        )
      );
    });
  }

  const data: any = await response.json();

  return new QuestionPattern(data.id, data.name, data.helpText);
}

async function loadDifficultyLevelByID(id: number): Promise<DifficultyLevel> {
  const response = await fetchWrapper(`/papi/question-level/${id}`, {
    headers: {
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    return response.json().then((error) => {
      return Promise.reject(
        new Error(
          `failed to get question-difficulty-level info: ${error.code} / ${error.message}`
        )
      );
    });
  }

  const data: any = await response.json();

  if (!Object.values(DifficultyLevel).includes(data.name)) {
    return Promise.reject(
      new Error(`unexpected question difficulty-level: ${data.name}`)
    );
  }

  return DifficultyLevel[data.name as keyof typeof DifficultyLevel];
}

async function loadQuestionOptionsByQuestionID(
  id: number
): Promise<QuestionOption[]> {
  const response = await fetchWrapper(
    `/papi/institution/question/${id}/options`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  if (!response.ok) {
    return response.json().then((error) => {
      return Promise.reject(
        new Error(
          `failed to get question-options: ${error.code} / ${error.message}`
        )
      );
    });
  }

  const data: any = await response.json();
  const options: QuestionOption[] = data.options.map((option: any) => ({
    id: option.id,
    text: option.text,
  }));

  return options;
}

async function loadQuestionByID(id: number): Promise<Question> {
  const response: Response = await fetchWrapper(
    `/papi/institution/question/${id}`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  if (!response.ok) {
    return response.json().then((error) => {
      return Promise.reject(
        new Error(
          `failed to get question info: ${error.code} / ${error.message}`
        )
      );
    });
  }

  const data: any = await response.json();
  let q: Partial<Question> = {
    id: id,
    summary: data.question,
    description: data.description,
    answerExplanation: data.answerExplanation,
    answer: data.answerId,
  };

  if (data.groupedQuestionInfo !== undefined) {
    const parentQ: Question = await loadQuestionByID(
      data.groupedQuestionInfo.parentQuestionId
    );
    const groupedQuestionInfo: GroupedQuestionInfo = {
      subQuesSeq: data.groupedQuestionInfo.subQuestionSeq,
      groupParent: parentQ,
    };
    q.groupedQuestionInfo = groupedQuestionInfo;
  }

  const pattern: QuestionPattern = await loadPatternByID(data.patternId);
  q = { ...q, pattern };

  const level: DifficultyLevel = await loadDifficultyLevelByID(data.levelId);
  q = { ...q, difficultyLevel: level };

  const options: QuestionOption[] = await loadQuestionOptionsByQuestionID(id);
  q = { ...q, options };

  return q as Question;
}

async function loadWordQuestionFromPapiWordInfo(
  w: DictionaryWord,
  wordInfo: PapiWordInfo
): Promise<WordQuestion> {
  switch (wordInfo.typ) {
    case WordQuestionType.Prompt:
      return {
        word: w,
        pattern: WordQuestionType.Prompt,
      } as WordQuestion;

    case WordQuestionType.MultiMeaning:
      var optionPromises: Promise<QuestionOption>[] = [];
      for (const id of wordInfo.data.wordOptionIDs) {
        optionPromises.push(
          loadWordQuestionByID(id).then((w: DictionaryWord) => {
            return {
              text: w.meaning,
            } as QuestionOption;
          })
        );
      }

      return Promise.all(optionPromises).then((options: QuestionOption[]) => {
        return {
          word: w,
          pattern: WordQuestionType.MultiMeaning,
          options: options,
        } as WordQuestion;
      });

    case WordQuestionType.ReverseMeaning:
      var optionPromises: Promise<QuestionOption>[] = [];
      for (const id of wordInfo.data.wordOptionIDs) {
        optionPromises.push(
          loadWordQuestionByID(id).then((w: DictionaryWord) => {
            return {
              text: w.name,
            } as QuestionOption;
          })
        );
      }

      return Promise.all(optionPromises).then((options: QuestionOption[]) => {
        return {
          word: w,
          pattern: WordQuestionType.ReverseMeaning,
          options: options,
        } as WordQuestion;
      });

    default:
      throw new Error(
        "unexpected word practice pattern response from PAPI: " +
          JSON.stringify(wordInfo)
      );
  }
}

export async function LoadPracticeByID(
  psID: number,
  pID: number
): Promise<Practice> {
  const response = await fetchWrapper(
    `/papi/institution/practice-session/${psID}/practice/${pID}`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  if (response.status !== StatusCodes.OK) {
    return response.json().then((error) => {
      return Promise.reject(
        new Error(
          `failed to get practice info: ${error.code} / ${error.message}`
        )
      );
    });
  }

  const data: PapiPracticeGETResponse = await response.json();

  switch (data.practiceableType) {
    case "App\\Models\\Question":
      const q: Question = await loadQuestionByID(data.practiceableId);
      return {
        practiceableType: "App\\Models\\Question",
        id: pID,
        isAdaptiveSelection: data.adaptive,
        sequence: data.sequence,
        userSeenAt: undefined,
        question: q,
        userSelectedAnswerIndices: data.userSelectedAnswerIndices,
        userResponseText: data.userResponseText,
        userResponseFileId: data.userResponseFileId,
        answerSubmitted:
          (data.userSelectedAnswerIndices !== undefined &&
            data.userSelectedAnswerIndices.length > 0) ||
          (data.userResponseText !== undefined &&
            data.userResponseText.length > 0) ||
          data.userResponseFileId !== undefined,
        userSkipped: data.userSkipped,
      } as Practice;
    case "App\\Models\\DictionaryWord":
      const w: DictionaryWord = await loadWordQuestionByID(data.practiceableId);
      if (data.wordInfo === undefined) {
        throw new Error("wordInfo is not defined by PAPI for DictionaryWord");
      }
      w.id = data.practiceableId;
      const wordQuestion: WordQuestion = await loadWordQuestionFromPapiWordInfo(
        w,
        data.wordInfo
      );
      return {
        practiceableType: "App\\Models\\DictionaryWord",
        id: pID,
        isAdaptiveSelection: data.adaptive,
        sequence: data.sequence,
        userSeenAt: undefined,
        userSelectedAnswerIndices: data.userSelectedAnswerIndices,
        answerSubmitted:
          data.userSelectedAnswerIndices !== undefined &&
          data.userSelectedAnswerIndices.length > 0,
        wordQuestion: wordQuestion,
      } as Practice;
    default:
      throw new Error(
        `Unsupported practice-type response from controller: ${data.practiceableType}`
      );
  }
}

async function loadPracticesByPracticeSessionID(
  id: number
): Promise<Practice[]> {
  return fetchWrapper(`/papi/institution/practice-session/${id}/practice`, {
    headers: {
      Accept: "application/json",
    },
  }).then(async (resp) => {
    if (!resp.ok) {
      return resp.json().then((error) => {
        return Promise.reject(
          new Error(
            `failed to list practices: ${error.code} / ${error.message}`
          )
        );
      });
    }

    return resp.json().then(async (data) => {
      var practicePromises: Promise<Practice>[] = [];

      for (const practiceData of data.practices) {
        practicePromises.push(LoadPracticeByID(id, practiceData.id));
      }

      return Promise.all(practicePromises);
    });
  });
}

async function loadWordQuestionByID(id: number): Promise<DictionaryWord> {
  return fetchWrapper(`/papi/institution/dictionary-word/${id}`, {
    headers: {
      Accept: "application/json",
    },
  })
    .then(async (resp) => {
      return resp.json();
    })
    .catch((error: any) => {
      throw new Error(
        `failed to dictionary-word: ${error.code} / ${error.message}`
      );
    });
}

export async function InitializeNextPracticeOfPracticeSessionByID(
  psID: number
): Promise<Practice> {
  return fetchWrapper(`/papi/institution/practice-session/${psID}/practice`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(async (resp: Response) => {
    if (resp.status !== StatusCodes.OK) {
      return Promise.reject({
        code: resp.status,
        message: `failed to create practice-session's next practice: ${resp.status} / ${resp.statusText}`,
      } as PapiError);
    }

    return resp
      .json()
      .then(async (data: PapiPracticesPOSTResponse) =>
        LoadPracticeByID(psID, data.practiceId)
      );
  });
}

export async function LoadPracticeSessionByID(
  id: number
): Promise<PracticeSession> {
  const response: Response = await fetchWrapper(
    `/papi/institution/practice-session/${id}`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  if (!response.ok) {
    return response.json().then((error) => {
      return Promise.reject(
        new Error(
          `failed to get practice-session info: ${error.code} / ${error.message}`
        )
      );
    });
  }

  const data: PapiPracticeSessionGETResponse = await response.json();

  const topic: Topic = await loadTopicByID(data.topicId);
  let wordlist: Wordlist | undefined = undefined;
  if (data.wordlistId !== undefined) {
    wordlist = await loadWordlistByID(data.wordlistId);
  }

  const exams: Exam[] = await Promise.all(
    data.examIDs.map((examID) => loadExamByID(examID))
  );
  const knownPractices: Practice[] = await loadPracticesByPracticeSessionID(id);
  let pt: PracticeTemplate | undefined = undefined;
  if (data.practiceTemplateId !== undefined) {
    pt = await loadPracticeTemplateByID(data.practiceTemplateId);
  }

  return new PracticeSession(
    id,
    data.name,
    LearningModeFromString(data.learningMode),
    topic,
    exams,
    knownPractices,
    data.totalWordQuestions,
    data.totalQuestions,
    StatusFromString(data.status),
    wordlist,
    pt
  );
}

export async function loadPracticeTemplateSectionByID(
  insttId: number,
  id: number
): Promise<PracticeTemplateSection> {
  const response: Response = await fetchWrapper(
    `/papi/institution/practice-template/${insttId}/section/${id}`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  if (!response.ok) {
    return response.json().then((error) => {
      return Promise.reject(
        new Error(
          `failed to get practice-template info: ${error.code} / ${error.message}`
        )
      );
    });
  }

  const data: PracticeTemplateSection = await response.json();
  const questionInfos: SectionQuestionInfo[] = data.questionInfos.map(
    (questionInfo) => ({
      id: questionInfo.id,
      score: questionInfo.score,
    })
  );

  return new PracticeTemplateSection(
    id,
    data.name,
    questionInfos,
    data.timeLimitSeconds,
    data.negativeMarkingPercentage
  );
}

export async function loadPracticeTemplateByID(
  id: number
): Promise<PracticeTemplate> {
  const response: Response = await fetchWrapper(
    `/papi/institution/practice-template/${id}`,
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  if (!response.ok) {
    return response.json().then((error: PapiError) => {
      return Promise.reject(
        new Error(
          `failed to get practice-template info: ${error.code} / ${error.message}`
        )
      );
    });
  }

  const data: PapiPracticeTemplateGETResponse = await response.json();

  const sectionPromises: Promise<PracticeTemplateSection>[] = data.sectionIds.map(
    (sId) => loadPracticeTemplateSectionByID(id, sId)
  );
  const sections: PracticeTemplateSection[] = await Promise.all(
    sectionPromises
  );

  return new PracticeTemplate(
    data.name,
    sections,
    PracticeTemplateStatusFromString(data.status),
    data.liveExamStartsAt
  );
}

export async function UploadUserFile(f: File): Promise<PapiFilePOSTResponse> {
  var formData = new FormData();
  formData.append("file", f);

  return fetchWrapper("/papi/user/file", {
    method: "POST",
    body: formData,
  }).then((resp: Response) => {
    if (resp.status !== StatusCodes.OK) {
      return Promise.reject({
        code: resp.status,
        message: `failed to upload file to the user account: ${resp.status} / ${resp.statusText}`,
      } as PapiError);
    }

    return resp.json();
  });
}

export async function LoadPracticeSessions(): Promise<
  PracticeSessionListInfoItem[]
> {
  const response: Response = await fetchWrapper(
    "/papi/institution/practice-session",
    {
      headers: {
        Accept: "application/json",
      },
    }
  );

  if (response.status !== StatusCodes.OK) {
    return Promise.reject({
      code: response.status,
      message: `failed to list practice-sessions: ${response.status} / ${response.statusText}`,
    } as PapiError);
  }

  const resp: PapiPracticeSessionsGETResponse = await response.json();

  let items: PracticeSessionListInfoItem[] = [];

  for (let i = 0; i < resp.items.length; i++) {
    const item = resp.items[i];
    let status = Status.InProgress;
    switch (item.status) {
      case "inprogress":
        status = Status.InProgress;
        break;
      case "completed":
        status = Status.Completed;
        break;
      default:
        throw new Error(
          "unexpected practice-session status from PAPI: " + item.status
        );
    }

    items.push(
      new PracticeSessionListInfoItem(
        item.id,
        item.name,
        item.topicName,
        item.examNames,
        item.totalWordQuestions,
        item.totalQuestions,
        status,
        item.canCloneThisSession,
        item.wordlistName
      )
    );
  }

  return items;
}

// Practice template api
export const fetchPracticeTemplateData = async (
  setPracticeTemplateData: React.Dispatch<
    React.SetStateAction<PracticeTemplateData[]>
  >
) => {
  try {
    const response = await fetch(`/papi/institution/practice-template`);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Practice Template API Error: ${errorText}`);
    }

    const data: { items: PracticeTemplateData[] } = await response.json();
    setPracticeTemplateData(data.items);
  } catch (error: any) {
    console.error("Error fetching practice template data:", error.message);
  }
};

export async function UserLogout(): Promise<void> {
  return fetchWrapper("/papi/user/logout", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(async (resp: Response) => {
    if (resp.status !== StatusCodes.OK) {
      return Promise.reject({
        code: resp.status,
        message: `failed to logout user: ${resp.status} / ${resp.statusText}`,
      } as PapiError);
    }

    return Promise.resolve();
  });
}

export async function UserLogin(
  email: string,
  password: string
): Promise<void> {
  return fetchWrapper("/papi/user/login", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      password: password,
    } as PapiUserLoginPOSTRequest),
  }).then(async (resp: Response) => {
    if (resp.status !== StatusCodes.OK) {
      return Promise.reject({
        code: resp.status,
        message: `failed to login user: ${resp.status} / ${resp.statusText}`,
      } as PapiError);
    }

    return Promise.resolve();
  });
}

/**
 * Export certain client functions needed for Laravel backwards compatibility.
 */
(window as any).papi = {
  UserLogin: UserLogin,
};
