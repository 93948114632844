import React, { useEffect, useRef, useState, MouseEvent } from "react";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import { IonIcon, IonImg } from "@ionic/react";
import {
  settings,
  toggle,
  logOut,
  caretDown,
  personCircleOutline,
} from "ionicons/icons";
import { UserLogout } from "../../papiclient/client";

const menuItems = [
  {
    text: "Settings",
    href: "/UserProfile/Users_Profile",
    icon: settings,
    id: "ProfileSettings",
    dataToggle: null,
    dataTarget: null,
  },
  {
    text: "Switch Role / Institution",
    href: "/change_role",
    icon: toggle,
    id: "SwitchRole",
    dataToggle: null,
    dataTarget: null,
  },
  // this feature awaited untill PWA completion
  // {
  //   text: "Give Feedback",
  //   icon: chatbubbleEllipsesOutline,
  //   id: "GiveFeedback",
  //   dataToggle: "modal",
  //   dataTarget: "#feedbackModal",
  // },
  {
    text: "Logout",
    href: "#",
    icon: logOut,
    id: null,
    dataToggle: null,
    dataTarget: null,
  },
];

interface UserData {
  name: string;
  institutionName: string;
  role: string;
  emailId: string;
  profileImage: string;
}

interface DropdownMenuProps {
  userData: UserData | null;
  darkMode: boolean;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ userData, darkMode }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  const handleLogout = () => {
    UserLogout()
      .then(async () => {
        window.location.href = "/ui/home";
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  return (
    <div className="menu-container">
      <button
        onClick={onClick}
        className={`menu-trigger ${
          darkMode ? "dark-mode-color" : "light-mode-color"
        }`}
      >
        {userData ? (
          <>
            {userData?.profileImage ? (
              <img
                src={userData?.profileImage}
                alt="user-image"
                className="user_icon w-[45px] h-[45px] rounded-full object-cover"
                id="user_icon"
              />
            ) : (
              <IonImg
                src={process.env.PUBLIC_URL + "/assets/img/account-avatar.png"}
                alt="account Icon"
                style={{ maxWidth: "30px" }}
                className="user_icon"
                id="user_icon"
              ></IonImg>
            )}
            <span className="navbar-font-color">
              Hi {userData?.name?.split(" ")[0]}
            </span>
            <IonIcon
              icon={caretDown}
              style={{
                fontSize: "14px",
                marginLeft: "5px",
              }}
            />
          </>
        ) : (
          <>
            <IonImg
              src={process.env.PUBLIC_URL + "/assets/img/account-avatar.png"}
              alt="account Icon"
              style={{ maxWidth: "30px" }}
            ></IonImg>
            <span className="navbar-font-color">Account</span>
            <IonIcon
              icon={caretDown}
              style={{
                fontSize: "14px",
                marginLeft: "5px",
              }}
            />
          </>
        )}
      </button>

      <nav
        ref={dropdownRef}
        className={`menu ${isActive ? "active" : "inactive"} ${
          darkMode ? "dark-mode-color" : "light-mode-color"
        }`}
      >
        <ul>
          {userData ? (
            menuItems.map((item, index) => (
              <li key={index}>
                <a
                  href={item.href}
                  style={{ display: "inline-block", color: "white" }}
                  {...(item.id ? { id: item.id } : {})}
                  {...(item.dataToggle
                    ? { "data-toggle": item.dataToggle }
                    : {})}
                  {...(item.dataTarget
                    ? { "data-target": item.dataTarget }
                    : {})}
                  onClick={(ev: MouseEvent<HTMLAnchorElement>) => {
                    if (item.text === "Logout") {
                      ev.preventDefault();
                      handleLogout();
                    }
                  }}
                >
                  {item.icon && (
                    <IonIcon icon={item.icon} style={{ marginRight: "6px" }} />
                  )}
                  {item.text}
                </a>
              </li>
            ))
          ) : (
            <>
              <li>
                <a
                  href="/login"
                  style={{ display: "inline-block", color: "white" }}
                >
                  <IonIcon
                    icon={personCircleOutline}
                    style={{ marginRight: "6px" }}
                  />
                  Login
                </a>
              </li>
              <li>
                <a
                  href="/register"
                  style={{ display: "inline-block", color: "white" }}
                >
                  <IonIcon
                    icon={personCircleOutline}
                    style={{ marginRight: "6px" }}
                  />
                  Sign Up
                </a>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default DropdownMenu;
